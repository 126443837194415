import OlMapManager, { AddLayerParams, InitMapParams } from '@/lib/OlMapManager';
import { Coordinate } from 'ol/coordinate';
import { transform } from 'ol/proj';
import { METERS_PER_UNIT } from 'ol/proj/Units';
import TileLayer, { Options } from 'ol/layer/Tile';
import * as sphere from 'ol/sphere';
import { Location } from 'src/models';
import BingMaps from 'ol/source/BingMaps';
import TileImage from 'ol/source/TileImage';
import XYZSource from 'ol/source/XYZ';
import TileWMS from 'ol/source/TileWMS';
import { boundingExtent, Extent } from 'ol/extent';
import LayerSwitcher from 'ol-layerswitcher';
import Overlay from 'ol/Overlay';
import axios from 'axios';
import { FitOptions } from 'ol/View';
import {
  MapBrowserEvent,
  MapEvent,
  Map as OlMap,
  View as OlView,
} from 'ol';
import Layer from 'ol/layer/Layer';
import { FeatureCollection, JointFeatureCollection, PoleFeatureCollection } from '@/models/apis/getGeoInfoResponse';
import VectorLayer, { Options as BaseVectorOptions } from 'ol/layer/Vector';
import LayerGroup, { Options as LayerGroupOptions } from 'ol/layer/Group';
import ExtremeMapDownloadButtonControl from '@/lib/ExtremeMapDownloadButtonControl';

let geoApiBaseURL = '';
if (process.env.VUE_APP_GEO_API_HOST) {
  const proto = process.env.VUE_APP_GEO_API_PROTO;
  const host = process.env.VUE_APP_GEO_API_HOST;
  const port = process.env.VUE_APP_GEO_API_PORT;
  geoApiBaseURL = `${proto}://${host}:${port}`;
}
geoApiBaseURL += '/geoserver/land/wms';

export interface CoordinateTransformOptions {
  srcProj?: string;
  destProj?: string;
}

export class NamedLayerGroup extends LayerGroup {
  name: string;

  constructor(name: string, opt_options?: LayerGroupOptions) {
    super(opt_options);
    this.name = name;
  }
}

export interface PopupLayer {
  popupOverlay: Overlay;
  popupCloser: HTMLElement;
  popupContent: HTMLElement;
}

export interface GetPopupLayerParams {
  popupContainer: HTMLElement;
  popupCloser: HTMLElement;
  popupContent: HTMLElement;
  popupOffset?: number[];
  onPopupClose?: () => void;
}

export interface NamedLayer extends Layer {
  name?: string;
}

export class NamedVectorLayer extends VectorLayer {
  name?: string;

  constructor(name: string, opt_options?: BaseVectorOptions) {
    super(opt_options);
    this.name = name;
  }
}

class NamedTileLayer extends TileLayer {
  name?: string;
}
interface TileOptionsWithTitle extends Options {
  title?: string;
}

export interface MapFeatureInfo {
  name: string;
  data: FeatureCollection;
}

export default class OlMapWrapper {
  mapMgr = new OlMapManager();

  initMapManager(params: InitMapParams): void {
    this.mapMgr.initMap(params);
  }
  coordFromLonLat(lon: number, lat: number, opt: CoordinateTransformOptions = {}): Coordinate {
    const destProj = opt.destProj || 'EPSG:3857';
    return transform([lon, lat], 'EPSG:4326', destProj);
  }
  convCoord({ lat, lon }: Location, opt: CoordinateTransformOptions = {}): Coordinate {
    const srcProj = opt.srcProj || 'EPSG:4326';
    const destProj = opt.destProj || 'EPSG:3857';
    return transform([lon, lat], srcProj, destProj);
  }
  getDegreesPerMeter(): number {
    return 1 / METERS_PER_UNIT['degrees'];
  }
  getCoordDistance(c1: number[], c2: number[]): number {
    return sphere.getDistance(c1, c2);
  }
  getMap(): OlMap | null {
    return this.mapMgr.getMap();
  }
  getView(): OlView | null {
    return this.mapMgr.getView();
  }
  getCenter(): Coordinate | undefined {
    return this.mapMgr.getCenter();
  }
  setCenter(to: Coordinate): void {
    this.mapMgr.setCenter(to);
  }
  getZoom(): number | undefined {
    return this.mapMgr.getZoom();
  }
  setZoom(to: number): void {
    this.mapMgr.setZoom(to);
  }
  getExtent(): Extent | undefined {
    return this.mapMgr.getExtent();
  }
  getBingMapLayer(): NamedTileLayer {
    // http://openlayers.org/en/v3.14.2/examples/bing-maps.html
    const styles = [
      'Road',
      'Aerial',
      'AerialWithLabels',
    ];
    const layer = new NamedTileLayer({
      // visible: false,
      // preload: Infinity,
      source: new BingMaps({
        key: 'AjTZgCFwpJQs_5feMLVLOWAGOYxT2ALlkniFCUvUfSrQVueWI83KqusIQaWTqy35',
        imagerySet: styles[2],
      }),
    });
    layer.name = 'bing';
    return layer;
  }
  getGoogleMapLayer(): NamedTileLayer {
    const layer = new NamedTileLayer({
      source: new TileImage({
        url: 'http://mt{0-3}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}',
        // url: 'http://maps.google.com/maps/vt?pb=!1m5!1m4!1i{z}!2i{x}!3i{y}!4i256!2m3!1e0!2sm!3i375060738!3m9!2spl!3sUS!5e18!12m1!1e47!12m3!1e37!2m1!1ssmartmaps!4e0'
      }),
    });
    layer.name = 'gmap';
    return layer;
  }
  getKokudoChiriinLayer(): NamedTileLayer {
    const layer = new NamedTileLayer({
      source: new XYZSource({
        url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
        projection: 'EPSG:3857',
        attributions: '出典：<a href="http://maps.gsi.go.jp/development/ichiran.html" target="_blank">国土地理院</a>',
        crossOrigin: 'anonymous',
      }),
    });
    layer.name = 'chiriin';
    return layer;
  }
  getGISLayer(layerName: string, layerTitle: string, zIndexOffset: number): NamedTileLayer {
    const options: TileOptionsWithTitle = {
      title: layerTitle,
      visible: false,
      zIndex: 20 + zIndexOffset,
      source: new TileWMS({
        url: geoApiBaseURL,
        crossOrigin: 'anonymous',
        params: {
          FORMAT: 'image/png',
          VERSION: '1.1.1',
          TILED: true,
          LAYERS: `land:${layerName}`,
          STYLES: '',
        },
      }),
    };
    const layer = new NamedTileLayer(options);
    layer.name = layerName;
    return layer;
  }
  checkArgNum(num: number, args: (NamedLayer | AddLayerParams | NamedLayerGroup)[]): void {
    const arr = [].slice.apply(args).filter(e => {
      return e !== undefined;
    });
    if (arr.length < num) {
      throw Error(`checkArgNum. There should be ${num} arguments.`);
    }
  }
  addLayer(layer: NamedLayer | NamedLayerGroup, opts: AddLayerParams = {}): void {
    this.checkArgNum(1, [ layer, opts ]);
    this.mapMgr.addLayer(layer, opts);
  }
  addLayers(arr: { layer: Layer; opts: AddLayerParams }[]): void {
    arr.forEach(e => {
      this.mapMgr.addLayer(e.layer, e.opts);
    });
  }
  getLayer(layerName: string): NamedLayer | NamedLayerGroup | null {
    return this.mapMgr.getLayer(layerName);
  }
  removeLayer(layerName: string): void {
    return this.mapMgr.removeLayer(layerName);
  }
  fitToExtent(extent: Extent, opts: FitOptions): void {
    if (!this.mapMgr.view) { return; }
    this.mapMgr.view.fit(extent, opts);
  }
  fitToCoordsBoundingExtent(coords: Coordinate[], opts: FitOptions): void {
    const extent = boundingExtent(coords);
    if (!this.mapMgr.view) { return; }
    this.mapMgr.view.fit(extent, opts);
  }
  updateMapSize(): void {
    if (!this.mapMgr.map) { return; }
    this.mapMgr.map.updateSize();
  }
  enableLayerSwitcher(): void {
    const layerSwitcher = new LayerSwitcher({
      tipLabel: 'レイヤー切替',
    });
    if (!this.mapMgr.map) { return; }
    this.mapMgr.map.addControl(layerSwitcher);
    const layerSwitcherElement = document.querySelector('.layer-switcher');
    if (layerSwitcherElement) {
      (layerSwitcherElement as HTMLElement).style.zIndex = '99999';
    }
  }
  enableDownloadButton(): void {
    const map = this.getMap();
    if (!map) return;

    const buttonControl = new ExtremeMapDownloadButtonControl();
    if (!this.mapMgr.map) return;

    this.mapMgr.map.addControl(buttonControl);
  }

  onMapSingleClick(func: (evt: MapBrowserEvent) => void): void {
    if (!this.mapMgr.map) { return; }
    this.mapMgr.map.on('singleclick', (evt: MapBrowserEvent) => {
      func(evt);
    });
  }
  onMapMoveEnd(func: (evt: MapEvent) => void): void {
    if (!this.mapMgr.map) { return; }
    this.mapMgr.map.on('moveend', (evt: MapEvent) => {
      func(evt);
    });
  }
  getPopupLayer(params: GetPopupLayerParams): PopupLayer {
    const popupContainer = params.popupContainer;
    const popupCloser = params.popupCloser;
    const popupContent = params.popupContent;

    const popupOverlay = new Overlay({
      element: popupContainer,
      offset: params.popupOffset || [0, -40],
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    const onPopupClose = params.onPopupClose || function() {};
    popupCloser.onclick = () => {
      popupOverlay.setPosition(undefined);
      popupCloser.blur();
      setTimeout(() => {
        onPopupClose();
      }, 0);
      return false;
    };

    return { popupOverlay, popupCloser, popupContent };
  }
  addPopupLayer({ popupOverlay }: PopupLayer): void {
    if (!this.mapMgr.map) { return; }
    const map = this.mapMgr.map;
    map.addOverlay(popupOverlay);
  }
  async getMapFeatureInfo(evt: MapBrowserEvent): Promise<MapFeatureInfo> {
    const layers = [this.getLayer('poles'), this.getLayer('joints')];
    const resolution = this.getView()?.getResolution() || 1;

    const promises: Promise<{name: string; data: PoleFeatureCollection | JointFeatureCollection}>[] = [];
    layers.forEach(layer => {
      if (!layer || !layer.getVisible()) {
        return;
      }

      const url = ((layer as NamedLayer).getSource() as TileWMS).getGetFeatureInfoUrl(
        evt.coordinate,
        resolution,
        'EPSG:3857',
        {INFO_FORMAT: 'application/json'},
      );
      if (!url) { return; }
      const promise = axios.get<PoleFeatureCollection | JointFeatureCollection>(url).then(({ data }) => {
        return {
          name: layer.name || '',
          data: data,
        };
      });
      promises.push(promise);
    });
    return Promise.all(promises).then(responses => {
      // featuresの中身が入ってる中で最初のやつ
      const info = responses.filter(({ data }) => {
        return data.features.length > 0;
      })[0];
      return info;
    });
  }
  showPolePopupFromFeatureInfo(popupLayerObj: PopupLayer, data: PoleFeatureCollection, coord: Coordinate): void {
    if (!data.features || data.features.length === 0) { return; }
    const { popupOverlay, popupContent } = popupLayerObj;
    popupOverlay.setOffset([0, -2]);

    const pole = data.features[0].properties;
    popupContent.innerHTML =
      `<p class="title">【${pole.code}】</p>
      <p class="info-row">
        <span class="lbl">路線名:</span>
        <span class="val">${pole.route}</span>
      </p>
      <p class="info-row">
        <span class="lbl">方向:</span>
        <span class="val">${pole.direction}</span>
      </p>`;
    popupOverlay.setPosition(coord);
  }
  showJointPopupFromFeatureInfo(popupLayerObj: PopupLayer, data: JointFeatureCollection, coord: Coordinate): void {
    if (!data.features || data.features.length === 0) { return; }
    const { popupOverlay, popupContent } = popupLayerObj;
    popupOverlay.setOffset([0, -2]);

    const joint = data.features[0].properties;
    popupContent.innerHTML =
      `<p class="title">【${joint.joint_number.trim()}】</p>
      <p class="info-row">
        <span class="lbl">路線名:</span>
        <span class="val">${joint.road_name}</span>
      </p>
      <p class="info-row">
        <span class="lbl">方向:</span>
        <span class="val">${joint.direction}</span>
      </p>`;
    popupOverlay.setPosition(coord);
  }
}
