import { CommonHeader, SanpuBlock } from '@/models/apis/johaisetsu/johaisetsuCommon';
import {
  TaskForceInfos,
  PlanningBlock,
  TaskForce,
  HoursPerKMGroupCm,
} from '@/models/johaisetsu/johaisetsuCommon';
import VueRouter from 'vue-router';
import { JohaisetsuSagyouJoukyouRoadName } from '@/models/apis/master/masterResponse';
import { dtFormat } from '@/lib/dateHelper';
import { MiscInfoForCar } from '@/models/johaisetsu/johaisetsuMap';
import { isRoleDriver } from '@/lib/roleHelper';

export interface AreaShowOption {
  name: string;
  order: number;
}

export const ROLE_HONSHA = 'honsha';
const COVERED_AREA_ALL = 'all';
const JOHAISETSU_REPORT_SETTOU_PATROL = 'settouPatrolReports';
const JOHAISETSU_REPORT_PROGRESS_MANAGER = 'settouProgressManagerReports';
const JOHAISETSU_REPORT_ONSITE_MANAGER = 'settouOnsiteManagerReports';
export const SETTOU_PATROL_DISPLAY_NAME = '雪凍パトロール報告';
export const SETTOU_PROGRESS_MANAGER_DISPLAY_NAME = '進捗管理専任者';
export const SETTOU_ONSITE_MANAGER_DISPLAY_NAME = '現場指揮者';
export const SETTOU_ONSITE_MANAGEMENT_DISPLAY_NAME = [
  SETTOU_PROGRESS_MANAGER_DISPLAY_NAME,
  SETTOU_ONSITE_MANAGER_DISPLAY_NAME,
].join('・');
export const SETTOU_REPORT_LAYER_TYPE_NAME = '報告書';
export type SettouOnsiteManagementWorkType = '進捗管理専任者' | '現場指揮者';

export const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
export const THREE_DAYS_IN_MILLISECONDS = ONE_DAY_IN_MILLISECONDS * 3;

const AREA_DISP_MAP1: Record<string, AreaShowOption> = {
  't-west': { name: '西', order: 1 },
  't-east': { name: '東', order: 2 },
  'kanagawa': { name: '神', order: 3 },
};

export const johaisetsuAreas = [
  { name: 'west', dispName: '西局', johaisetsuRole: 't-west' },
  { name: 'east', dispName: '東局', johaisetsuRole: 't-east' },
  { name: 'kanagawa', dispName: '神奈川', johaisetsuRole: 'kanagawa' },
];

// 凍結防止作業
export const JOHAISETSU_TYPE_ENSUI_SANPU = 'ensui_sanpu';
export const JOHAISETSU_TYPE_SHITSUEN_SANPU = 'shitsuen_sanpu';
export const JOHAISETSU_TYPE_KOKEIZAI_SANPU = 'kokeizai_sanpu';
export const JOHAISETSU_TYPE_KYOKUSHO_SANPU = 'kyokusho_sanpu';
export const JOHAISETSU_TYPE_OTHER_SANPU = 'other_sanpu';
// 融雪作業
export const JOHAISETSU_TYPE_SWEEPER_SOUKOU = 'sweeper_soukou';
export const JOHAISETSU_TYPE_OTHER_SOUKOU = 'other_soukou';
// 規制作業
export const JOHAISETSU_TYPE_KISEI = 'kisei';
// 除雪作業
export const JOHAISETSU_TYPE_JOSETSU = 'josetsu';
// 排雪作業
export const JOHAISETSU_TYPE_HAISETSU = 'haisetsu';

const commonWorkTypes = [
  { type: '凍結防止作業',
    items: [
      { id: JOHAISETSU_TYPE_ENSUI_SANPU, label: '塩水散布' },
      { id: JOHAISETSU_TYPE_SHITSUEN_SANPU, label: '湿塩散布' },
      { id: JOHAISETSU_TYPE_KOKEIZAI_SANPU, label: '固形材散布' },
      { id: JOHAISETSU_TYPE_KYOKUSHO_SANPU, label: '局所散布' },
      { id: JOHAISETSU_TYPE_OTHER_SANPU, label: 'その他' },
    ],
  },
  { type: '融雪作業',
    items: [
      { id: JOHAISETSU_TYPE_SWEEPER_SOUKOU, label: 'スイーパー' },
      { id: JOHAISETSU_TYPE_OTHER_SOUKOU, label: 'その他' },
    ],
  },
];

export const layers = [
  ...commonWorkTypes,
  { type: 'それ以外の作業',
    items: [
      { id: JOHAISETSU_TYPE_KISEI, label: '規制' },
      { id: JOHAISETSU_TYPE_JOSETSU, label: '除雪' },
      { id: JOHAISETSU_TYPE_HAISETSU, label: '排雪' },
    ],
  },
  { type: '報告書',
    items: [
      { id: JOHAISETSU_REPORT_SETTOU_PATROL, label: SETTOU_PATROL_DISPLAY_NAME, isShow: true },
      { id: JOHAISETSU_REPORT_PROGRESS_MANAGER, label: SETTOU_PROGRESS_MANAGER_DISPLAY_NAME, isShow: true },
      { id: JOHAISETSU_REPORT_ONSITE_MANAGER, label: SETTOU_ONSITE_MANAGER_DISPLAY_NAME, isShow: true },
    ],
  },
];
type DateType = 'settouPatrolReports' | 'settouProgressManagerReports' | 'settouOnsiteManagerReports';

interface SettouReportMapping {
  itemKey: DateType;
  stateKey: DateType;
  displayName: string;
}

export const settouReportMappings: SettouReportMapping[] = [
  {
    itemKey: 'settouPatrolReports',
    stateKey: 'settouPatrolReports',
    displayName: SETTOU_PATROL_DISPLAY_NAME,
  },
  {
    itemKey: 'settouProgressManagerReports',
    stateKey: 'settouProgressManagerReports',
    displayName: SETTOU_PROGRESS_MANAGER_DISPLAY_NAME,
  },
  {
    itemKey: 'settouOnsiteManagerReports',
    stateKey: 'settouOnsiteManagerReports',
    displayName: SETTOU_ONSITE_MANAGER_DISPLAY_NAME,
  },
];

export const workTypes = [
  ...commonWorkTypes,
  { type: '規制作業',
    items: [
      { id: JOHAISETSU_TYPE_KISEI, label: '規制作業' },
    ],
  },
  { type: '除雪作業',
    items: [
      { id: JOHAISETSU_TYPE_JOSETSU, label: '除雪作業' },

    ],
  },
  { type: '排雪作業',
    items: [
      { id: JOHAISETSU_TYPE_HAISETSU, label: '排雪作業' },
    ],
  },
];

export const settouPatrolReports = [
  { type: SETTOU_PATROL_DISPLAY_NAME,
    items: [
      { id: JOHAISETSU_REPORT_SETTOU_PATROL, label: SETTOU_PATROL_DISPLAY_NAME },
    ],
  },
  { type: '進捗管理専任者・現場指揮者',
    items: [
      { id: JOHAISETSU_REPORT_PROGRESS_MANAGER, label: SETTOU_PROGRESS_MANAGER_DISPLAY_NAME },
      { id: JOHAISETSU_REPORT_ONSITE_MANAGER, label: SETTOU_ONSITE_MANAGER_DISPLAY_NAME },
    ],
  },
];

export const validWorkTypesMap = workTypes.reduce<Map<string, Set<string>>>((acc, workType) => {
  const itemLabels = workType.items.map(item => item.label);
  acc.set(workType.type, new Set(itemLabels));
  return acc;
}, new Map<string, Set<string>>());

export const typeRemarks: Record<string, MiscInfoForCar> = {
  [JOHAISETSU_TYPE_ENSUI_SANPU]: {johaisetsuType: '凍結防止作業', workType: '散布中', color: '#F50000'},
  [JOHAISETSU_TYPE_SHITSUEN_SANPU]: {johaisetsuType: '凍結防止作業', workType: '散布中', color: '#F50000'},
  [JOHAISETSU_TYPE_KOKEIZAI_SANPU]: {johaisetsuType: '凍結防止作業', workType: '散布中', color: '#F50000'},
  [JOHAISETSU_TYPE_KYOKUSHO_SANPU]: {johaisetsuType: '凍結防止作業', workType: '散布中', color: '#F50000'},
  [JOHAISETSU_TYPE_OTHER_SANPU]: {johaisetsuType: '凍結防止作業', workType: '散布中', color: '#F50000'},
  [JOHAISETSU_TYPE_SWEEPER_SOUKOU]: {johaisetsuType: '融雪作業', workType: '走行中', color: '#18FFFF'},
  [JOHAISETSU_TYPE_OTHER_SOUKOU]: {johaisetsuType: '融雪作業', workType: '走行中', color: '#18FFFF'},
  [JOHAISETSU_TYPE_KISEI]: {johaisetsuType: '規制作業', workType: '規制中', color: '#F5F325'},
  [JOHAISETSU_TYPE_JOSETSU]: {johaisetsuType: '除雪作業', workType: '除雪中', color: '#1F4BFF'},
  [JOHAISETSU_TYPE_HAISETSU]: {johaisetsuType: '排雪作業', workType: '排雪中', color: '#A001C3'},
};

export const reportConstMap: Record<string, {componentName: string; title: string}> = {
  [SETTOU_PATROL_DISPLAY_NAME]: {
    componentName: 'SettouPatrolReportDetail',
    title: '雪凍パトロール報告書詳細',
  },
  [SETTOU_ONSITE_MANAGEMENT_DISPLAY_NAME]: {
    componentName: 'SettouOnsiteManagementReportDetail',
    title: '雪凍現場報告詳細',
  },
};

const maxSanpuNum = 30;
export const sanpuNums = Array.from({ length: maxSanpuNum }, (_, i) => i + 1);

export function getTaskForceInfos(taskForceId: number | string): TaskForceInfos {
  const taskForces = window.johaisetsuMaster.taskForces;
  const tmpTaskForce = window.johaisetsuMaster.taskForceMap[taskForceId.toString()];
  const selectedTaskForce = tmpTaskForce || taskForces[0];
  return { taskForces, selectedTaskForce };
}

export function getJohaisetsuRoadNameInfos(): JohaisetsuSagyouJoukyouRoadName[] {
  return JSON.parse(JSON.stringify(
    window.johaisetsuMaster.sagyouJoukyouRoadNameDirections)) as JohaisetsuSagyouJoukyouRoadName[];
}

export function getJohaisetsuPlanningBlocks(): PlanningBlock[] {
  return (window.johaisetsuMaster.planningBlocks).map(e => {
    return Object.assign({}, e);
  });
}

export function getJohaisetsuHoursPerKmGroupCm(): HoursPerKMGroupCm[] {
  return (window.johaisetsuMaster.hoursPerKmGroupCm).map(e => {
    return Object.assign({}, e);
  });
}

export function getJohaisetsuEnsuiSanpuBlocks(): SanpuBlock[] {
  return (window.johaisetsuMaster.ensuiSanpuBlocks).map(e => {
    return Object.assign({}, e);
  });
}

export function getJohaisetsuJutenSanpuBlocks(): SanpuBlock[] {
  return (window.johaisetsuMaster.jutenSanpuBlocks).map(e => {
    return Object.assign({}, e);
  });
}

export function isDataReadOnly(selectedTaskForce: TaskForce, selectedHeader: CommonHeader | undefined, johaisetsuRole: string): boolean {
  // * 対策本部に終了日が入っている場合はRO
  // * headerがcurrentではない場合(スナップショットの場合)はRO
  //   (headerがない場合はcurrentを見ていると推定する)
  // * 本社はRO
  return !!selectedTaskForce.end_date ||
    (selectedHeader && selectedHeader.name !== 'current') ||
    johaisetsuRole === ROLE_HONSHA;
}

export function onChangeUrlPathParam(router: VueRouter, routeName: string, taskForceId: number | string, headerId: string): void {
  const routeObj = {
    name: routeName,
    params: {
      taskForceId: taskForceId.toString(),
      headerId: headerId,
    },
  };
  router.push(routeObj);
}

export function convHeadersForList(taskForce: TaskForce, headers: CommonHeader[], johaisetsuRole: string): CommonHeader[] {
  // currentの表示時刻はmin(現在, taskForceの終了時刻)とする
  let nowForCurrent = new Date();
  if (taskForce.end_date) {
    const taskForceEndDate = new Date(taskForce.end_date);
    taskForceEndDate.setHours(23);
    taskForceEndDate.setMinutes(59);
    if (taskForceEndDate.valueOf() < nowForCurrent.valueOf()) {
      nowForCurrent = taskForceEndDate;
    }
  }
  const nameDispOfTypeCurrent = dtFormat(nowForCurrent, 'mm/dd HH:MM 現在');
  // 空だったらダミーのcurrentが入った配列を返しとく
  if (!headers || headers.length === 0) {
    return [{
      idConv: 'current',
      name: 'current',
      nameDisp: nameDispOfTypeCurrent,
    }];
  }

  return headers.filter(e => {
    // 局の場合はcovered_areaに自分が入ってなかったら関係ないので見せない
    if (johaisetsuRole === ROLE_HONSHA) { return true; }
    if (e.covered_area === COVERED_AREA_ALL) { return true; }
    return e.covered_area?.includes(johaisetsuRole);
  }).map(e => {
    const ret = Object.assign({}, e);
    let idConv = ret.id?.toString();
    let nameDisp = ret.name;
    if (ret.type === 'current') {
      idConv = 'current';
      nameDisp = nameDispOfTypeCurrent;
    } else {
      if (johaisetsuRole === ROLE_HONSHA) {
        // 本社側ではどこの局が定時報告送信済か見えるようにする
        const coveredAreaConv = e.covered_area?.split(',')
          .sort((a, b) => {
            const v1 = (AREA_DISP_MAP1[a] || { order: 99 }).order;
            const v2 = (AREA_DISP_MAP1[b] || { order: 99 }).order;
            return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
          })
          .map(area => {
            return (AREA_DISP_MAP1[area] || { name: '' }).name;
          }).join(',');
        nameDisp += `(${coveredAreaConv})`;
      }
    }
    ret.idConv = idConv || '';
    ret.nameDisp = nameDisp;
    return ret;
  });
}

export function getDefaultNameForRegularReport(): string {
  let tm = new Date();
  const minutes = tm.getMinutes();
  if (minutes < 15) {
    tm.setMinutes(0);
  } else if (minutes < 45) {
    tm.setMinutes(30);
  } else {
    if (tm.getHours() === 23) {
      tm = new Date(tm.valueOf() + 86400 * 1000);
    }
    tm.setHours(tm.getHours() + 1);
    tm.setMinutes(0);
  }
  const dateTimeDisp = dtFormat(tm, 'mm/dd HH:MM');
  return `${dateTimeDisp} 定時報告`;
}

export function isJohaisetsuMap(path: string | null = ''): boolean {
  return !!path && path.includes('johaisetsu-map');
}

export function getGeoItemColor(sanpuNum: number): string {
  const x = sanpuNum;
  if (x < 2) {
    // 1 <= x < 2
    return '#ee8383';
  } else if (x < 3) {
    // 2 <= x < 3
    return '#4274b1';
  } else if (x < 4) {
    // 3 <= x < 4
    return '#75fc4c';
  } else if (x < 5) {
    // 4 <= x < 5
    return '#c49b33';
  } else if (x < 10) {
    // 5 <= x < 10
    return '#ea3323';
  } else if (x < 15) {
    // 10 <= x < 15
    return '#5e813f';
  } else if (x < 20) {
    // 15 <= x < 20
    return '#ed702d';
  } else {
    // 20 <= x
    return '#75fbfd';
  }
}

export function isJohaisetsuCompanyDefaultUser(johaisetsuRole: string): boolean {
  return !!johaisetsuRole && !isRoleDriver(johaisetsuRole);
}
