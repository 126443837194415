import Control from 'ol/control/Control';
import { downloadBlob } from '@/lib/downloadHelper';

export default class ExtremeMapDownloadButtonControl extends Control {
  constructor(opt_options?: { target?: HTMLElement }) {
    const options = opt_options || {};

    const button = document.createElement('button');
    button.type = 'button';
    button.innerHTML = '<i class="fas fa-download"></i>';
    Object.assign(button.style, {
      borderRadius: '4px',
      border: '1px solid #ccc',
      position: 'absolute',
      backgroundColor: 'white',
      color: '#666',
      top: '5em',
      right: '10px',
      height: '25px',
      zIndex: '1000',
    });
    button.classList.add('extreme-map-button-control');

    const element = document.createElement('div');
    element.appendChild(button);

    super({
      element: element,
      target: options.target,
    });

    button.addEventListener('click', this.handleExtremeMapDownloadButton.bind(this), false);
  }

  handleExtremeMapDownloadButton(): void {
    const map = this.getMap();
    if (!map) return;
    map.once('postcompose', function(event) {
      const canvas = event.context.canvas;
      canvas.toBlob(function(blob) {
        if (!blob) return;
        downloadBlob(blob, 'map.jpeg');
      });
    });
    map.renderSync();
  }
}
