


import {
  computed,
  reactive,
  defineComponent,
  PropType,
  onMounted,
  ref,
  onBeforeUnmount,
} from '@vue/composition-api';
import boardEntryApi from '@/apis/board_entry';
import { dtFormat } from '@/lib/dateHelper';
import {
  fetchFileAsObjectInfo,
  convertFilesToFileObjs,
  FILE_TYPE_UNKNOWN,
} from '@/lib/fileHelper';
import { HanMaster } from '@/models/apis/settouMessageBoard/settouMessageBoardResponse';
import {
  BoardEntryConv,
  BoardEntryEdit,
  PreviewFile,
  BoardEntryEditModalState,
  AttachedFileObj,
} from '@/models/settouMessageBoard';
import {
  LONG_TEXT_MAX_LENGTH,
  ATTACHMENT_FILE_MAX_MB_DEFAULT,
  MAX_SAVABLE_FILES_COUNT,
} from '@/components/SettouMessageBoard/consts/board_entry_edit_modal';
import { AxiosError } from 'axios';
import { getErrorMessages } from '@/lib/errMsgHelper';
import {
  getEditingBoardEntryDefault,
  BOARD_ENTRY_ATTACHED_TO_CONTENT,
  BOARD_ENTRY_ATTACHED_TO_REPLY,
} from '@/components/SettouMessageBoard/utils';
import useBoardDisplayControl from '@/components/SettouMessageBoard/composables/useBoardDisplayControl';
import { AttachedFile } from '@/models/apis/settouMessageBoard/settouMessageBoardRequest';
import PreviewFilesArea from '@/components/SettouMessageBoard/components/BoardEntryEditModal/PreviewFilesArea/index.vue';
import RichTextEditor from '@/components/lib/RichTextEditor.vue';

export default defineComponent({
  name: 'board-entry-edit-modal',
  props: {
    boardEntry: {
      type: Object as PropType<BoardEntryConv>,
      required: false,
      nullable: true,
    },
    hanChoices: {
      type: Array as PropType<HanMaster[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const modalDialog = ref<HTMLElement>();
    const modalHeader = ref<HTMLElement>();
    const modalFooter = ref<HTMLElement>();

    const state = reactive<BoardEntryEditModalState>({
      editingBoardEntry: getEditingBoardEntryDefault(),
      editingBoardEntryOrig: getEditingBoardEntryDefault(),
      isCreate: false,
      shouldShowEditClearConfirmModal: false,
      shouldShowErrorModal: false,
      errorBody: '',
      previewFiles: [],
    });

    const {
      currentBoardTab,
      isCreatableRole,
      isRepliableRole,
      isHonbuBoard,
      isMaintenanceBoard,
      boardTabDepartmentDisp,
      isUpperOrganization,
    } = useBoardDisplayControl();

    const sortedContentPreviewFiles = computed<PreviewFile[]>(() => {
      return state.previewFiles.filter(e => e.attachedTo === BOARD_ENTRY_ATTACHED_TO_CONTENT)
        .sort((a, b) => a.id - b.id);
    });
    const sortedReplyPreviewFiles = computed<PreviewFile[]>(() => {
      return state.previewFiles.filter(e => e.attachedTo === BOARD_ENTRY_ATTACHED_TO_REPLY)
        .sort((a, b) => a.id - b.id);
    });
    const canEditReply = computed<boolean>(() => {
      return !isUpperOrganization.value && (state.isCreate || isCreatableRole.value);
    });
    onMounted(() => {
      if (!props.boardEntry) {
        prepareCreateBoardEntry();
        return;
      }
      prepareEditBoardEntry(props.boardEntry);
    });
    const prepareCreateBoardEntry = (): void => {
      const now = new Date();
      const formObj = getEditingBoardEntryDefault();
      formObj.timestamp = now.toISOString();
      formObj.date = dtFormat(formObj.timestamp, 'yyyy/mm/dd');
      formObj.time = dtFormat(formObj.timestamp, 'HH:MM');
      if (isUpperOrganization.value && isHonbuBoard.value) {
        formObj.isHonshaReportNeeded = true;
        formObj.isReportedToHonsha = true;
      }
      state.editingBoardEntry = formObj;
      prepareOrigValues(formObj);
      state.isCreate = true;
    };
    const prepareEditBoardEntry = (origFormObj: BoardEntryConv): void => {
      const formObj = {
        ...origFormObj,
        date: dtFormat(origFormObj.timestamp, 'yyyy/mm/dd'),
        time: dtFormat(origFormObj.timestamp, 'HH:MM'),
        companyName: origFormObj.company_name,
        isHonshaReportNeeded: origFormObj.is_honsha_report_needed,
        isReportedToHonsha: origFormObj.is_reported_to_honsha,
        saveFiles: [],
        removeFileIds: [],
      };
      const boardEntryFiles = origFormObj.board_entry_files ?? [];
      boardEntryFiles.forEach(async(e) => {
        const savedFileInfo = await fetchFileAsObjectInfo(e.file_path);
        if (!savedFileInfo) return;
        const savedFileContent = savedFileInfo.url;
        if (!savedFileContent) return;
        const savedFileType = savedFileInfo.type ?? FILE_TYPE_UNKNOWN;
        const savedFileName = e.file_name;
        state.previewFiles.push({
          id: e.id,
          content: savedFileContent,
          type: savedFileType,
          name: savedFileName,
          attachedTo: e.attached_to,
        });
      });
      prepareOrigValues(formObj);
      state.editingBoardEntry = formObj;
      state.isCreate = false;
    };
    const prepareOrigValues = (formObj: BoardEntryEdit): void => {
      state.editingBoardEntryOrig = { ...formObj };
      const now = new Date();
      state.editingBoardEntryOrig.date = dtFormat(formObj.timestamp ?? now, 'yyyy/mm/dd');
      state.editingBoardEntryOrig.time = dtFormat(formObj.timestamp ?? now, 'HH:MM');
    };

    const modalBodyMaxHeight = computed<number>(() => {
      const windowH = window.innerHeight;
      const dialogOffsetTop = modalDialog.value?.offsetTop || 0;
      const modalHeaderH = modalHeader.value?.clientHeight || 0;
      const modalFooterH = modalFooter.value?.clientHeight || 0;
      return windowH - modalHeaderH - modalFooterH - dialogOffsetTop * 2;
    });
    const onChangeBoardEntryDate = (): void => {
      const dateStr = dtFormat(state.editingBoardEntry.date, 'yyyy/mm/dd');
      if (!dateStr) return;
      state.editingBoardEntry.date = dtFormat(state.editingBoardEntry.date, 'yyyy/mm/dd');
    };
    const onChangeBoardEntryTime = (): void => {
      const timeStr = dtFormat(`${state.editingBoardEntry.date} ${state.editingBoardEntry.time}:00`, 'HH:MM');
      if (!timeStr) return;
      state.editingBoardEntry.time = timeStr;
    };
    const handleFileUpload = async(fileList: FileList, attachedTo: string) => {
      const attachedFiles = state.previewFiles.filter(e => e.attachedTo === attachedTo);
      const uploadFilesCount = fileList.length + attachedFiles.length;
      if (uploadFilesCount > MAX_SAVABLE_FILES_COUNT) {
        state.errorBody = '添付ファイルの最大数は' + MAX_SAVABLE_FILES_COUNT + '個です。';
        state.shouldShowErrorModal = true;
        return;
      }
      // 新規ファイルのidを連番で割り振る(実際のidはサーバー側で振られる)
      const maxId = state.previewFiles.map(e => e.id).reduce((a, b) => Math.max(a, b), 0);
      try {
        const fileObjs = await convertFilesToFileObjs(fileList, maxId, ATTACHMENT_FILE_MAX_MB_DEFAULT);
        const attachedFileObjs = fileObjs.map(e => {
          return {
            ...e,
            attachedTo: attachedTo,
          };
        });
        state.previewFiles.push(...attachedFileObjs);
        state.editingBoardEntry.saveFiles.push(...attachedFileObjs);
      } catch (error) {
        // 複数エラーは発生しない想定だが、先頭のエラーメッセージを表示する
        state.errorBody = getErrorMessages(error as AxiosError)[0];
        state.shouldShowErrorModal = true;
      }
    };
    const removeFile = (fileId: number) => {
      state.previewFiles = state.previewFiles.filter(e => e.id !== fileId);
      const saveFile = state.editingBoardEntry.saveFiles.find(e => e.id === fileId);
      if (saveFile) {
        state.editingBoardEntry.saveFiles = state.editingBoardEntry.saveFiles.filter(e => e.id !== fileId);
        return;
      }
      state.editingBoardEntry.removeFileIds.push(fileId);
    };

    const saveBoardEntry = async(): Promise<void> => {
      if (!checkInputs(state.editingBoardEntry)) return;
      try {
        if (state.isCreate) {
          await createBoardEntry();
        } else {
          if (isRepliableRole.value) {
            await replyBoardEntry();
          } else {
            // 管理者は連絡事項を含む全ての項目が編集可
            // 管理者以外は自身の投稿の連絡事項以外が編集可
            await updateBoardEntry();
          }
        }
        releaseAllFileResources();
        emit('save-board-entry');
      } catch (e) {
        console.error('error', e);
        state.errorBody = '保存に失敗しました。再度保存を行ってください';
        state.shouldShowErrorModal = true;
      }
    };
    const createBoardEntry = async() => {
      const params = {
        timestamp: toTimestampStr(state.editingBoardEntry.date, state.editingBoardEntry.time),
        board_master_id: currentBoardTab.value.id,
        company_name: state.editingBoardEntry.companyName ?? null,
        han: state.editingBoardEntry.han ?? null,
        is_honsha_report_needed: state.editingBoardEntry.isHonshaReportNeeded,
        is_reported_to_honsha: state.editingBoardEntry.isReportedToHonsha,
        content: formatText(state.editingBoardEntry.content, LONG_TEXT_MAX_LENGTH),
        reply: formatText(state.editingBoardEntry.reply, LONG_TEXT_MAX_LENGTH),
        bikou: formatText(state.editingBoardEntry.bikou, LONG_TEXT_MAX_LENGTH),
        files: convFileObjsToSaveFiles(state.editingBoardEntry.saveFiles),
      };
      await boardEntryApi.create(params);
    };
    const replyBoardEntry = async() => {
      const params = {
        reply: formatText(state.editingBoardEntry.reply, LONG_TEXT_MAX_LENGTH),
        save_files: convFileObjsToSaveFiles(state.editingBoardEntry.saveFiles),
        remove_file_ids: state.editingBoardEntry.removeFileIds,
      };
      await boardEntryApi.reply(
        state.editingBoardEntry.id,
        params);
    };
    const updateBoardEntry = async() => {
      const saveFiles = [
        ...convFileObjsToSaveFiles(state.editingBoardEntry.saveFiles),
      ];
      const params = {
        timestamp: toTimestampStr(state.editingBoardEntry.date, state.editingBoardEntry.time),
        board_master_id: currentBoardTab.value.id,
        company_name: state.editingBoardEntry.companyName ?? null,
        han: state.editingBoardEntry.han ?? null,
        is_honsha_report_needed: state.editingBoardEntry.isHonshaReportNeeded,
        is_reported_to_honsha: state.editingBoardEntry.isReportedToHonsha,
        content: formatText(state.editingBoardEntry.content, LONG_TEXT_MAX_LENGTH),
        reply: formatText(state.editingBoardEntry.reply, LONG_TEXT_MAX_LENGTH),
        bikou: formatText(state.editingBoardEntry.bikou, LONG_TEXT_MAX_LENGTH),
        save_files: saveFiles,
        remove_file_ids: state.editingBoardEntry.removeFileIds,
      };
      await boardEntryApi.update(
        state.editingBoardEntry.id,
        params);
    };
    const convFileObjsToSaveFiles = (fileObjs: AttachedFileObj[]): AttachedFile[] => {
      return fileObjs.map(e => {
        return {
          file: e.file,
          attached_to: e.attachedTo,
        };
      });
    };
    const isReadyToSubmit = (formObj: BoardEntryEdit): boolean => {
      return checkInputs(formObj);
    };
    const formatText = (text: string | null, maxLength: number): string => {
      if (!text) return '';
      if (text.length <= maxLength) return text;
      return text.substring(0, maxLength);
    };
    const toTimestampStr = (dateStr?: string | null, timeStr?: string | null): string => {
      if (!dateStr || !timeStr) return '';
      return `${dtFormat(dateStr, 'yyyy-mm-dd')} ${timeStr}:00`;
    };
    const checkInputs = (formObj: BoardEntryEdit): boolean => {
      if (isUpperOrganization.value && state.editingBoardEntry.reply) return true;
      if (isHonbuBoard.value && !formObj.han) return false;
      if (isMaintenanceBoard.value && !formObj.companyName) return false;
      if (!formObj.date ||
          !formObj.time ||
          !formObj.content ||
          formObj.content.match(/^\s*$/)) {
        return false;
      }
      const timestampStr = toTimestampStr(formObj.date, formObj.time);
      const m = timestampStr.match(/^(\d{4})[^0-9](\d{2})[^0-9](\d{2})[^0-9](\d{2})[^0-9](\d{2})[^0-9](\d{2})$/);
      if (!m) return false;
      return true;
    };
    const tryCancelEditBoardEntry = (): void => {
      if (isEdited(state.editingBoardEntry)) {
        state.shouldShowEditClearConfirmModal = true;
      } else {
        cancelEditBoardEntry();
      }
    };
    const isEdited = (formObj: BoardEntryEdit): boolean => {
      // 値を編集していたら破棄してよいかの確認メッセージを出す
      if (formObj.date !== state.editingBoardEntryOrig?.date ||
          formObj.time !== state.editingBoardEntryOrig?.time ||
          formObj.han !== state.editingBoardEntryOrig?.han ||
          formObj.companyName !== state.editingBoardEntryOrig?.companyName ||
          formObj.isHonshaReportNeeded !== state.editingBoardEntryOrig?.isHonshaReportNeeded ||
          formObj.isReportedToHonsha !== state.editingBoardEntryOrig?.isReportedToHonsha ||
          formObj.content !== state.editingBoardEntryOrig?.content ||
          formObj.reply !== state.editingBoardEntryOrig?.reply ||
          formObj.bikou !== state.editingBoardEntryOrig?.bikou) {
        return true;
      }
      return false;
    };
    const cancelEditBoardEntry = (): void => {
      releaseAllFileResources();
      emit('cancel-edit-board-entry');
    };
    const releaseAllFileResources = () => {
      state.previewFiles.forEach(elm => URL.revokeObjectURL(elm.content));
      state.previewFiles = [];
      state.editingBoardEntry.saveFiles.forEach(elm => URL.revokeObjectURL(elm.content));
      state.editingBoardEntry.saveFiles = [];
      state.editingBoardEntry.removeFileIds = [];
    };

    onBeforeUnmount(() => {
      releaseAllFileResources();
    });

    return {
      state,
      // consts
      LONG_TEXT_MAX_LENGTH,
      BOARD_ENTRY_ATTACHED_TO_CONTENT,
      BOARD_ENTRY_ATTACHED_TO_REPLY,
      // refs
      modalDialog,
      modalHeader,
      modalFooter,
      // computed
      currentBoardTab,
      boardTabDepartmentDisp,
      isCreatableRole,
      isRepliableRole,
      isHonbuBoard,
      isMaintenanceBoard,
      canEditReply,
      sortedContentPreviewFiles,
      sortedReplyPreviewFiles,
      modalBodyMaxHeight,
      onChangeBoardEntryDate,
      onChangeBoardEntryTime,
      handleFileUpload,
      removeFile,
      saveBoardEntry,
      isReadyToSubmit,
      tryCancelEditBoardEntry,
      cancelEditBoardEntry,
    };
  },
  components: {
    PreviewFilesArea,
    RichTextEditor,
  },
});
