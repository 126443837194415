export const JOHAISETSU_STATUS_NONE = 0;
export const JOHAISETSU_STATUS_TSUKOU_DOME = 10;
export const JOHAISETSU_STATUS_JOSETSU_CHU = 20;
export const JOHAISETSU_STATUS_HAISETSU_CHU = 30;
export const JOHAISETSU_STATUS_HAISETSU_KANRYOU = 40;
export const JOHAISETSU_STATUS_JISSA_CHU = 50;
export const JOHAISETSU_STATUS_JISSA_KANRYOU = 60;

export const ENSUI_SANPU_STATUS_NONE = 0;
export const ENSUI_SANPU_STATUS_SANPU_CHU = 10;
export const ENSUI_SANPU_STATUS_SANPU_KANRYOU = 20;

export const JUTEN_SANPU_STATUS_NONE = 0;
export const JUTEN_SANPU_STATUS_SANPU_CHU = 10;
export const JUTEN_SANPU_STATUS_SANPU_KANRYOU = 20;

export const JOHAISETSU_ROLE_HONSHA = 'honsha';
export const JOHAISETSU_ROLE_WEST = 't-west';
export const JOHAISETSU_ROLE_EAST = 't-east';
export const JOHAISETSU_ROLE_KANAGAWA = 'kanagawa';
