import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import {
  BoardEntryIndexParams,
  SettouMessageBoardCreateRequest,
  SettouMessageBoardUpdateRequest,
  SettouMessageBoardReplyRequest,
} from '@/models/apis/settouMessageBoard/settouMessageBoardRequest';
import {
  SettouMessageBoardResponse,
  BoardTab,
  BoardMaster,
  HanMaster,
  BoardEntry,
} from '@/models/apis/settouMessageBoard/settouMessageBoardResponse';
export default {
  index(params: BoardEntryIndexParams): Promise<AxiosResponse<SettouMessageBoardResponse>> {
    return axios.get(utils.getApiUrl(`/board_entries`), {
      params: params || {},
    });
  },
  create(params: SettouMessageBoardCreateRequest): Promise<AxiosResponse<BoardEntry>> {
    const formData = utils.transformFormData(params);
    return axios.post(utils.getApiUrl('/board_entries'), formData);
  },
  update(id: number, params: SettouMessageBoardUpdateRequest): Promise<AxiosResponse<BoardEntry>> {
    const formData = utils.transformFormData(params);
    // putではFormDataのパラメータを受け取れないため
    // https://qiita.com/ky0he1_0t4/items/6cd8264f646bdf86cdb9
    return axios.post(utils.getApiUrl(`/board_entries/${id}`), formData, {
      headers: {
        'X-HTTP-Method-Override': 'PUT',
      },
    });
  },
  reply(id: number, params: SettouMessageBoardReplyRequest): Promise<AxiosResponse<BoardEntry>> {
    const formData = utils.transformFormData(params);
    // putではFormDataのパラメータを受け取れないため
    // https://qiita.com/ky0he1_0t4/items/6cd8264f646bdf86cdb9
    return axios.post(utils.getApiUrl(`/board_entries/reply/${id}`), formData, {
      headers: {
        'X-HTTP-Method-Override': 'PUT',
      },
    });
  },
  downloadCSV(params: BoardEntryIndexParams): Promise<AxiosResponse<Blob>> {
    return axios.get(utils.getApiUrl(`/board_entries/csv`), {
      params: params,
      responseType: 'blob',
    });
  },
  destroy(id: number): Promise<AxiosResponse<BoardEntry>> {
    return axios.delete(utils.getApiUrl(`/board_entries/${id}`));
  },
  getBoardTabs(): Promise<AxiosResponse<BoardTab[]>> {
    return axios.get(utils.getApiUrl(`/board_entries/board_tabs`));
  },
  getBoardMasters(): Promise<AxiosResponse<BoardMaster[]>> {
    return axios.get(utils.getApiUrl(`/board_entries/board_masters`));
  },
  getBoardHanMasters(): Promise<AxiosResponse<HanMaster[]>> {
    return axios.get(utils.getApiUrl('/board_entries/board_han_masters'));
  },
};
