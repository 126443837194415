


import {
  defineComponent,
  computed,
  onMounted,
  onBeforeUnmount,
  reactive,
  PropType,
} from '@vue/composition-api';
import { fetchImageAsObjectURL } from '@/lib/imageHelper';
import { downloadBlob } from '@/lib/downloadHelper';
import { dtFormat } from '@/lib/dateHelper';
import { RoadNameDirection } from '@/models';
import {
  SettouOnsiteManagementReport,
} from '@/models/apis/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsuSettouOnsiteManagementReportsResponse';
import {
  SettouOnsiteManagementReportRaw,
} from '@/models/johaisetsu/settouPatrol/settouOnsiteManagement/johaisetsuSettouOnsiteManagementReport';
import { SettouPatrolReportRoadConditionMap } from '@/models/apis/johaisetsu/johaisetsuCommon';

interface SettouOnsiteManagementReportDetailState {
  isReady: boolean;
  isRequesting: boolean;
  isDownloading: boolean;

  onsiteManagementReport: SettouOnsiteManagementReportRaw | null;

  roadNames: RoadNameDirection[];
  roadNameMap: Record<string, RoadNameDirection>;
  roadConditions: SettouPatrolReportRoadConditionMap[];
  roadConditionMap: { [key: string]: SettouPatrolReportRoadConditionMap };

  showErrorModal: boolean;
  errorBody: string;
}

export default defineComponent({
  props: {
    report: {
      type: Object as PropType<SettouOnsiteManagementReport>,
      required: true,
    },
  },
  setup(props) {
    const state = reactive<SettouOnsiteManagementReportDetailState>({
      isReady: false,
      isRequesting: false,
      isDownloading: false,
      onsiteManagementReport: null,
      roadNames: [],
      roadNameMap: {},
      roadConditions: [],
      roadConditionMap: {},
      showErrorModal: false,
      errorBody: '',
    });

    const onsiteManagementReportId = computed<number>(() => {
      return Number(state.onsiteManagementReport?.id);
    });
    const convOnsiteManagementReports = (data: SettouOnsiteManagementReport): SettouOnsiteManagementReportRaw => {
      let roadNameDisp = data.road_name_disp;
      const roadNameObj = state.roadNameMap[data.road_name_disp];
      if (roadNameObj) {
        roadNameDisp = roadNameObj.roadNameDisp;
      }
      const workArea = data.work_area ?? '';
      return {
        ...data,
        ts: new Date(data.ts),
        roadNameDisp: roadNameDisp,
        workArea: workArea,
        hasImage: data.photos.length > 0,
        savedImages: [],
        shouldShow: true,
      };
    };
    const loadReportData = async(): Promise<void> => {
      try {
        if (!props.report) {
          throw new Error('データの取得に失敗しました。再度操作を行ってください');
        }
        state.onsiteManagementReport = convOnsiteManagementReports(props.report);
        const imageObjectURLPromises: Promise<(string | null)>[] = props.report.photos.map(e => {
          return fetchImageAsObjectURL(e.image_path).catch(() => null);
        });
        const savedImages = await Promise.all(imageObjectURLPromises);
        if (state.onsiteManagementReport) {
          state.onsiteManagementReport.savedImages = savedImages;
        }
      } catch (e) {
        console.error('error', e);
        state.errorBody = 'データの取得に失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
      }
    };
    const downloadImage = async(image: Blob, idx: number): Promise<void> => {
      state.isDownloading = true;
      try {
        const reportId = onsiteManagementReportId.value;
        const dispOrder = idx + 1;
        const timestamp = dtFormat(new Date(), 'yyyymmddHHMMSS');
        const filename = `${state.onsiteManagementReport?.work_type}_${reportId}_写真_${dispOrder}_${timestamp}.jpg`;
        await downloadBlob(image, filename);
        state.isDownloading = false;
      } catch (e) {
        console.error('error', e);
        state.errorBody = '写真のダウンロードに失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
        state.isDownloading = false;
      }
    };
    const releaseImageObjectURLs = (): void => {
      const report = state.onsiteManagementReport;
      if (!report || !report.savedImages) {
        return;
      }
      report.savedImages.forEach(e => {
        if (e != null) {
          URL.revokeObjectURL(e);
        }
      });
    };

    onMounted((): void => {
      state.roadNames = JSON.parse(JSON.stringify(window.master.roadNameDirections.filter(e => !e.isDummy)));
      state.roadNameMap = state.roadNames.reduce<Record<string, RoadNameDirection>>((acc, e) => {
        acc[e.roadNameReal] = e;
        return acc;
      }, {});
      state.roadConditionMap = window.johaisetsuMaster.settouPatrolReportRoadConditionMap;
      state.roadConditions = window.johaisetsuMaster.settouPatrolReportRoadConditions;
      loadReportData();
      state.isReady = true;
    });

    onBeforeUnmount(() => {
      releaseImageObjectURLs();
    });
    return {
      state,
      // methods
      downloadImage,
      dtFormat,
    };
  },
});
