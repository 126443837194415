import { SettouReport } from '@/models/johaisetsu/johaisetsuCommon';
import { reactive, UnwrapRef } from '@vue/composition-api';

interface ReportModalState {
  displayReport: SettouReport | null;
  reportType: string;
}

export interface UseReportModalResult {
  reportModalState: UnwrapRef<ReportModalState>;
  openReportDetailModal: (report: SettouReport, reportType: string) => void;
  closeReportDetailModal: () => void;
}

export function useReportModal(): UnwrapRef<UseReportModalResult> {
  const reportModalState = reactive<ReportModalState>({
    displayReport: null,
    reportType: '',
  });

  const openReportDetailModal = (report: SettouReport, reportType: string): void => {
    reportModalState.displayReport = report;
    reportModalState.reportType = reportType;
  };

  const closeReportDetailModal = (): void => {
    reportModalState.displayReport = null;
    reportModalState.reportType = '';
  };

  return {
    reportModalState,
    openReportDetailModal,
    closeReportDetailModal,
  };
}
