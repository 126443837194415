

















import {
  defineComponent,
  PropType,
  computed,
} from '@vue/composition-api';

import SettouOnsiteManagementReportDetail from '@/components/lib/reportDetailModal/SettouOnsiteManagementReportDetail/index.vue';
import SettouPatrolReportDetail from '@/components/lib/reportDetailModal/SettouPatrolReportDetail/index.vue';
import { SettouReport } from '@/models/johaisetsu/johaisetsuCommon';
import { reportConstMap } from '@/lib/johaisetsu/johaisetsuCommonUtil';

export default defineComponent({
  components: {
    SettouPatrolReportDetail,
    SettouOnsiteManagementReportDetail,
  },
  props: {
    report: {
      type: Object as PropType<SettouReport>,
      required: true,
    },
    reportType: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const currentComponentName = computed<string>(() => reportConstMap[props.reportType]?.componentName ?? '');
    const currentTitle = computed<string>(() => reportConstMap[props.reportType]?.title ?? '');

    const tryCloseReportModal = () => {
      emit('close');
    };

    return {
      // computed
      currentComponentName,
      currentTitle,
      tryCloseReportModal,
    };
  },
});
