












import {
  defineComponent,
  reactive,
  watch,
  ref,
} from '@vue/composition-api';

import 'jodit/build/jodit.min.css';
import { JoditEditor } from 'jodit-vue';

interface RichTextEditorState {
  textValue: string;
}

interface EditorConfig {
  limitChars: number;
  limitHTML: boolean;
  language: string;
  minHeight: string;
  height: string;
  toolbarButtonSize: string;
  colorPickerDefaultTab: string;
  disabled: boolean;
  showTooltipDelay: number;
  defaultActionOnPaste: string;
  defaultLineHeight: number;
  askBeforePasteHTML: boolean;
  PoweredByJodit: boolean;
  countHTMLChars: boolean;
  showCharsCounter: boolean;
  showWordsCounter: boolean;
  addNewLine: boolean;
  image: {
    openOnDblClick: boolean;
  };
  history: {
    enable: boolean;
  };
  colors: {
    greyscale: string[];
    palette: string[];
    full: string[];
  };
}

export default defineComponent({
  components: {
    JoditEditor,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    textHeight: {
      type: String,
      default: '200px',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 1000,
    },
    fontSize: {
      type: String,
      default: 'small',
    },
    placeholder: {
      type: String,
      default: '内容を入力してください...',
    },
  },
  setup(props, { emit }) {
    const buttons = ['bold', 'underline', 'brush'];
    const editor = ref<InstanceType<typeof JoditEditor>>();
    const editorConfig = reactive<EditorConfig>({
      /**
       * JoditEditorコンポーネントの設定オプション
       * @see https://xdsoft.net/jodit/docs/options.html
       */
      limitChars: props.maxLength,
      limitHTML: true,
      language: 'ja',
      minHeight: props.textHeight,
      height: props.textHeight,
      toolbarButtonSize: 'small',
      colorPickerDefaultTab: 'forecolor',
      disabled: props.readOnly,
      showTooltipDelay: 10,
      defaultActionOnPaste: 'insert_as_html',
      defaultLineHeight: 1.3,
      askBeforePasteHTML: false,
      PoweredByJodit: false,
      countHTMLChars: false,
      showCharsCounter: true,
      showWordsCounter: false,
      addNewLine: false,
      image: {
        openOnDblClick: false,
      },
      history: {
        enable: false,
      },
      // 黒色を別コード（#222222）に指定する必要があるため、colors属性を追加
      colors: {
        greyscale: ['#222222', '#434343', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
        palette: ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
        full: [
          '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
          '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
          '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
          '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
          '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
          '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130',
        ],
      },
    });
    const state = reactive<RichTextEditorState>({
      textValue: props.value ?? '',
    });

    watch(() => state.textValue, (newValue) => {
      // 一度カーソルが入ると、入力がなくでも、下記のタグが挿入されるので、空文字に置き換え
      if (newValue === '<p><br></p>') newValue = '';

      emit('input', newValue);
    });
    watch(() => props.value, (newValue) => {
      state.textValue = newValue;
      if (state.textValue === '') state.textValue = '<p><br></p>';
    });

    watch(() => props.textHeight, (newValue) => {
      editorConfig.height = newValue;
      editorConfig.minHeight = newValue;
    });

    watch(() => props.readOnly, (newValue) => {
      if (!editor.value) return;

      editor.value.editor.setDisabled(newValue);
      editorConfig.disabled = newValue;
    });

    return {
      state,
      buttons,
      editor,
      editorConfig,
    };
  },
});
