


import {
  defineComponent,
  computed,
  onMounted,
  onBeforeUnmount,
  reactive,
  PropType,
} from '@vue/composition-api';
import { fetchImageAsObjectURL } from '@/lib/imageHelper';
import { downloadBlob } from '@/lib/downloadHelper';
import { dtFormat } from '@/lib/dateHelper';
import { RoadNameDirection } from '@/models';
import { SettouPatrolReportRoadConditionMap } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { SettouPatrolReport } from '@/models/apis/johaisetsu/settouPatrol/settouPatrol/johaisetsuSettouPatrolReportsResponse';
import { SettouPatrolReportRaw } from '@/models/johaisetsu/settouPatrol/settouPatrol/johaisetsuSettouPatrol';
import { SETTOU_PATROL_DISPLAY_NAME } from '@/lib/johaisetsu/johaisetsuCommonUtil';

interface SettouPatrolReportDetailState {
  isReady: boolean;
  isRequesting: boolean;
  isDownloading: boolean;

  report: SettouPatrolReportRaw | null;

  roadNames: RoadNameDirection[];
  roadNameMap: Record<string, RoadNameDirection>;
  roadConditions: SettouPatrolReportRoadConditionMap[];
  roadConditionMap: { [key: string]: SettouPatrolReportRoadConditionMap };

  showErrorModal: boolean;
  errorBody: string;
}
export default defineComponent({
  props: {
    report: {
      type: Object as PropType<SettouPatrolReport>,
      required: true,
    },
  },
  setup(props) {
    const state = reactive<SettouPatrolReportDetailState>({
      isReady: false,
      isRequesting: false,
      isDownloading: false,

      report: null,

      roadNames: [],
      roadNameMap: {},
      roadConditions: [],
      roadConditionMap: {},

      showErrorModal: false,
      errorBody: '',
    });

    const id = computed<number>(() => {
      return Number(state.report?.id);
    });

    const convReport = (data: SettouPatrolReport) => {
      let roadNameDisp = data.road_name_disp;
      const roadNameObj = state.roadNameMap[data.road_name_disp];
      if (roadNameObj) {
        roadNameDisp = roadNameObj.roadNameDisp;
      }

      let roadConditionText = '';
      const roadConditionObj = state.roadConditionMap[data.road_condition];
      if (roadConditionObj) {
        roadConditionText = roadConditionObj.text;
        const consitionsWithCm = [7, 8];
        if (consitionsWithCm.includes(parseInt(data.road_condition))) {
          roadConditionText = roadConditionText.replace(/\$\$/, data.snow_height.toString());
        }
      }

      data.hasImage = data.photos.length > 0;

      return {...data,
        ts: new Date(data.ts),
        roadNameDisp: roadNameDisp,
        roadConditionText: roadConditionText,
        hasImage: data.photos.length > 0,
        savedImages: [],
        shouldShow: true,
      };
    };
    const loadReportData = async(): Promise<void> => {
      state.isRequesting = true;
      try {
        if (!props.report) {
          throw new Error('データの取得に失敗しました。再度操作を行ってください');
        }
        state.report = convReport(props.report);
        const imageObjectURLPromises: Promise<(string | null)>[] = props.report.photos.map(e => {
          return fetchImageAsObjectURL(e.image_path).catch(() => null);
        });
        const savedImages = await Promise.all(imageObjectURLPromises);
        if (state.report) {
          state.report.savedImages = savedImages;
        }
      } catch (e) {
        console.error('error', e);
        state.errorBody = 'データの取得に失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
      } finally {
        state.isRequesting = false;
      }
    };
    const downloadImage = async(image: Blob, idx: number) => {
      state.isDownloading = true;
      try {
        const reportId = id.value;
        const dispOrder = idx + 1;
        const timestamp = dtFormat(new Date(), 'yyyymmddHHMMSS');
        const filename = `${SETTOU_PATROL_DISPLAY_NAME}_${reportId}_写真_${dispOrder}_${timestamp}.jpg`;
        await downloadBlob(image, filename);
        state.isDownloading = false;
      } catch (e) {
        console.error('error', e);
        state.errorBody = '写真のダウンロードに失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
        state.isDownloading = false;
      }
    };
    const releaseImageObjectURLs = () => {
      if (!state.report || !state.report.savedImages) {
        return;
      }
      state.report.savedImages.forEach(e => {
        if (e != null) {
          URL.revokeObjectURL(e);
        }
      });
    };

    onMounted((): void => {
      state.roadNames = JSON.parse(JSON.stringify(window.master.roadNameDirections.filter(e => !e.isDummy)));
      state.roadNameMap = state.roadNames.reduce<Record<string, RoadNameDirection>>((acc, e) => {
        acc[e.roadNameReal] = e;
        return acc;
      }, {});
      state.roadConditionMap = window.johaisetsuMaster.settouPatrolReportRoadConditionMap;
      state.roadConditions = window.johaisetsuMaster.settouPatrolReportRoadConditions;
      loadReportData();
      state.isReady = true;
    });

    onBeforeUnmount(() => {
      releaseImageObjectURLs();
    });
    return {
      state,
      // computed
      id,
      SETTOU_PATROL_DISPLAY_NAME,
      // methods
      convReport,
      fetchImageAsObjectURL,
      downloadImage,
      releaseImageObjectURLs,
      dtFormat,
    };
  },
});
